import React from "react";
import ReactDOM from "react-dom/client";
import Layout from "./src/layouts/index";

// wraps every page in the Layout component
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

// replace the default hydrate function to stop console errors...
// https://github.com/gatsbyjs/gatsby/discussions/36232#discussioncomment-6145538
export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
