import React from "react";
import * as styles from "./logo.module.scss";

import { Link } from "gatsby";

const Logo = () => {
  return (
    <div className={styles.logo}>
      <Link to="/" className={styles.title}>
        <span className={styles.katie}>katie</span>{" "}
        <span className={styles.k8e} aria-hidden={true}>
          k8e
        </span>{" "}
        <span className={styles.nance}>nance</span>
      </Link>
    </div>
  );
};

export default Logo;
