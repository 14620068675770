import React from "react";
import * as styles from "./header.module.scss";

import Nav from "../nav";
import Starfield from "../starfield";
import Logo from "../logo";

const Header = () => {
  return (
    <header className={styles.header}>
      <Starfield />
      <Logo />
      <Nav />
    </header>
  );
};

export default Header;
