import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

import * as styles from "./navmenu.module.scss";

// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_animations/Using_CSS_animations#animating_display_and_content-visibility
const NavMenu = ({
  links = [],
  showMenu: propShowMenu = false,
  closeMenu,
  hideNavBar: propHideNavBar = false,
}) => {
  const [showMenu, setShowMenu] = useState(propShowMenu);
  const [hideNavBar, setHideNavBar] = useState(propHideNavBar);

  useEffect(() => {
    setShowMenu(propShowMenu);
  }, [propShowMenu]);

  useEffect(() => {
    setHideNavBar(propHideNavBar);
  }, [propHideNavBar]);

  const fadeInStyle = {
    display: "flex",
    transform: "translateX(0)",
    animation: `${styles.fadeIn} 0.3s ease-in forwards`,
  };

  const fadeOutStyle = {
    transform: "translateX(100%)",
    animation: `${styles.fadeOut} 0.3s ease-out forwards`,
  };

  return (
    <nav
      className={styles.navmenu}
      style={showMenu ? fadeInStyle : fadeOutStyle}
      aria-hidden={!hideNavBar || !showMenu}
    >
      <ul>
        {links.map((link) => (
          <li key={link.to}>
            <Link to={link.to} onClick={closeMenu}>
              {link.text}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavMenu;
