import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";

import * as styles from "./nav.module.scss";
import NavMenu from "../navmenu";
import BurgerButton from "../burgerButton";

const links = [
  { to: "/", text: "Home" },
  { to: "/about", text: "About" },
  { to: "/contact", text: "Contact" },
  { to: "/blog", text: "Blog" },
];

const mediumScreen = 768;

const Nav = () => {
  const menuRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [hideNavBar, setHideNavBar] = useState(false);

  const toggleMenu = () => {
    setShowMenu((oldShowMenu) => !oldShowMenu);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > mediumScreen) {
        setHideNavBar(false);
        setShowMenu(false);
      } else {
        setHideNavBar(true);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const listener = (event) => {
      if (!menuRef.current || menuRef.current.contains(event.target)) {
        return;
      }
      setShowMenu(false);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        setShowMenu(false);
      }
    });

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("keydown", (event) => {
        if (event.key === "Escape") {
          setShowMenu(false);
        }
      });
    };
  }, [menuRef]);

  return (
    <nav className={styles.navbar} ref={menuRef}>
      <ul className={styles.navbarLinks} aria-hidden={hideNavBar}>
        {links.map((link) => (
          <li key={link.to}>
            <Link to={link.to}>{link.text}</Link>
          </li>
        ))}
      </ul>
      <BurgerButton showMenu={showMenu} toggleMenu={toggleMenu} />
      <NavMenu
        links={links}
        showMenu={showMenu}
        hideNavBar={hideNavBar}
        closeMenu={() => setShowMenu(false)}
      />
    </nav>
  );
};

export default Nav;
