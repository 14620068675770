import React, { useState, useEffect } from "react";

import * as styles from "./burgerButton.module.scss";

// https://css-tricks.com/hamburger-menu-with-a-side-of-react-hooks-and-styled-components/
const BurgerButton = ({ showMenu: propShowMenu, toggleMenu }) => {
  const [showMenu, setShowMenu] = useState(propShowMenu);
  const [ariaLabel, setAriaLabel] = useState("Open navigation");

  useEffect(() => {
    setShowMenu(propShowMenu);
    setAriaLabel(propShowMenu ? "Close navigation" : "Open navigation");
  }, [propShowMenu]);

  return (
    <button
      className={styles.burgerButton}
      onClick={() => toggleMenu()}
      aria-label={ariaLabel}
    >
      <div
        className={styles.burgerBar}
        style={{ transform: showMenu ? "rotate(45deg)" : "rotate(0)" }}
      />
      <div
        className={styles.burgerBar}
        style={{
          opacity: showMenu ? "0" : "1",
          transform: showMenu ? "translateX(-20px)" : "translateX(0)",
        }}
      />
      <div
        className={styles.burgerBar}
        style={{ transform: showMenu ? "rotate(-45deg)" : "rotate(0)" }}
      />
    </button>
  );
};

export default BurgerButton;
