import React, { useEffect, useState, useRef } from "react";

import * as styles from "./starfield.module.scss";

const Starfield = ({ numberOfStars = 100 }) => {
  const [stars, setStars] = useState([]);
  const starfieldRef = useRef(null);

  // from https://stackoverflow.com/a/71457870 but simplified a bit so it's like useMemo
  const useStarfieldSize = () => {
    const [starfieldSize, setStarfieldSize] = useState([undefined, undefined]);

    useEffect(() => {
      const handleResize = () => {
        setStarfieldSize([
          starfieldRef.current.offsetWidth,
          starfieldRef.current.offsetHeight,
        ]);
      };

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return starfieldSize;
  };

  const [width, height] = useStarfieldSize();

  useEffect(() => {
    if (!width || !height) {
      return;
    } else {
      const tempStars = Array.from(
        { length: numberOfStars },
        () => (
          <div
            className={styles.star}
            style={{
              left: `${Math.random() * width}px`,
              top: `${Math.random() * height}px`,
              opacity: Math.random(),
            }}
            key={Math.random()}
          />
        ),
        []
      );

      setStars(tempStars);
    }
  }, [width, height, numberOfStars]);

  return (
    <div ref={starfieldRef} className={styles.starfield} aria-hidden={true}>
      {stars}
    </div>
  );
};

export default Starfield;
